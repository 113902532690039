@charset "utf-8"
@import '../node_modules/animate.css/animate.css'
@import './fonts/index.sass'
@import '../node_modules/buiderall.css/fonts/fonts.css'

// Update Bulma's global variables
$family-sans-serif: 'Muli', sans-serif
// $grey-dark: $brown
// $grey-light: $beige-light
$primary: #0294ff
$link: $primary
// $widescreen-enabled: false
// $fullhd-enabled: false

// Update some of Bulma's component variables
// $body-background-color: $beige-lighter
// $control-border-width: 2px
// $input-border-color: transparent
// $input-shadow: none

@import '../node_modules/bulma/bulma.sass'

.button.is-square
  max-width: 40px !important
  &.is-small
    max-width: 30px !important

*[hidden]
  display: none !important

.modal
  padding: 1.5rem

.has-line-through
  text-decoration: line-through

.ba-app-content
  background: linear-gradient(20deg, #f8f4f4, #fff)
  min-height: 100vh
  height: 100%
  position: absolute
  top: 80px
  left: 54px
  right: 0
