.Schedules
  .vis-container
    height: 50vh
    padding: 0
    position: relative
    @media screen and (max-width: 425px)
      height: 75vh
    .vis-network
      position: absolute
      top: 0
      left: 0
      right: 0
      bottom: 0
      * 
        outline: none
  .remove-label
    flex: 1